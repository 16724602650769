import Paris from 'assets/images/paris.png'
import Image from 'components/Image'
import Button from 'components/home/common/Button'
import { ArrowRightIcon } from 'components/icons'
import Headers from 'components/home/common/typography/Headers'

const MagazineHero = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-x-90 lg:justify-center my-75 lg:my-[150px]">
      <div className="flex flex-col items-start justify-center max-w-[532px] px-20 mb-50">
        <p className="mb-20 font-bold uppercase text-grey-750 text-14 tracking-1/2">
          Magazine
        </p>

        <Headers.BaseTitle className="text-34 text-grey-900 xl:text-48 max-w-[280px] md:max-w-[380px]">
          Escape Into <br /> the World of <br /> A.M.A Selections
        </Headers.BaseTitle>

        <p className="my-25 text-14 text-grey-750">
          A realm of exquisite homes, fine dining, beautiful landscapes and endless possibilities. A travel and lifestyle magazine dedicated to capturing the beauty of our destinations, from the simple to the refined.
        </p>

        <Button
          href="/magazine/category"
          variant="secondary"
          icon={<ArrowRightIcon />}
        >
          View Articles
        </Button>
      </div>
      <div className="relative lg:max-w-[450px] h-300 sm:h-[400px] md:h-[500px] lg:h-[600px] w-full" >
        <Image
          className="absolute inset-0 object-cover"
          src={Paris}
          alt="View of the Eiffel Tower from a dining table"
          layout="fill"
        />
      </div>
    </div>
  )
}

export default MagazineHero
