import React from 'react'
import CarouselMagazine from 'components/carousels/CarouselMagazine'
import { RestArticlePage } from 'data/types/article'

const MostViewed = ({ articles }: { articles: RestArticlePage }) => {
  return articles && (
    <CarouselMagazine articles={articles.items} />
  )
}

export default MostViewed
