import ApiClient from 'data/api/api_client'
import { useRouter } from 'next/router'
import TopPicks from 'components/magazines/TopPicks'
import FeaturedArticle from 'components/magazines/FeaturedArticle'
import TravelGuides from 'components/magazines/TravelGuides'
import MostViewed from 'components/magazines/MostViewed'
import magazineHero from 'assets/images/magazine-hero.jpeg'
import CreateAnAccount from 'components/magazines/CreateAnAccount'
import { config } from 'data/config'
import { PromiseSettledResult } from 'data/types/api'
import { GetServerSidePropsContext } from 'next'
import { RestArticle, RestArticlePage, RestArticlePagePaginated, RestArticleTaxonomy } from 'data/types/article'
import PublicLayout, { PublicLayoutProps } from 'layouts/PublicLayout'
import ArticleList from 'components/magazines/ArticleList'
import Link from 'components/Link'
import Button from 'components/home/common/Button'
import MagazineSearchBar from 'components/magazines/category-searchbar/MagazineSearchBar'
import MagazineHero from 'components/magazines/Home/MagazineHero'
import SectionHeaders from 'components/home/common/typography/SectionHeaders'
import { MobileScreenWidths, useScreenWidth } from 'data/hooks/useScreenWidth'
import MobileArticleList from 'components/magazines/Home/MobileArticleList'
import { InfiniteData, useQuery } from 'react-query'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import { FormProvider, useForm } from 'react-hook-form'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'
import LogoLink from 'components/logoLink'
import MagazineSearchbar from 'components/magazines/category-searchbar/MagazineNavbar'
import { useHasScrolled } from 'data/hooks/useHasScrolled'
import queryCache from 'data/queryCache'
import { joinBy } from '@ama-selections/ui'
import he from 'he'
import { useSearchParams } from 'data/hooks/useSearchParams'

interface MagazinePageProps {
  initialData: {
    categories: []
    featuredArticle: RestArticle
    topPicks: RestArticlePage
    recentArticles: RestArticlePagePaginated
    travelGuides: RestArticlePage
    mostViewed: RestArticlePage
  }
}

export interface MagazineSearchFormFields {
  categories: number[],
  destination: SelectedDestinationData | undefined
  destination_search: string | undefined
  search: string | undefined
}

const MagazinePage = ({ initialData }: MagazinePageProps) => {
  const router = useRouter()
  const hasScrolled = useHasScrolled()

  const { isMobile } = useScreenWidth(MobileScreenWidths.LG)
  const { isMobileApp } = useMobileApp()
  const { params } = useSearchParams()

  const seo: PublicLayoutProps['seo'] = {
    title: 'A Curated Travel & Lifestyle Magazine for Luxury Holidays in France, Italy & Spain',
    description: 'A curated travel & lifestyle magazine. Explore the most exclusive holiday homes, local tips & recommendations on the French Riviera, Italy, and Spain.',
    openGraph: {
      type: 'blog',
      url: config.appURL + router.asPath,
    },
  }

  const { data: categories } = useQuery(
    queryCache.magazineCategories,
    () => ApiClient.magazine.getCategories(),
  )

  const form = useForm<MagazineSearchFormFields>()

  const mobileNavbar: PublicLayoutProps['mobileNavBar'] = {
    images: [{
      url: magazineHero.src,
      alt_text: 'Car driving through a vineyard',
    }],
    links: hasScrolled
      ? categories?.map((category: RestArticleTaxonomy) => ({
        label: he.decode(category.name),
        href: joinBy([
          `/magazine/category?categories=${category.id}`,
          params.exclude('categories').toString(),
        ], '&'),
        active: params.get('categories')?.includes(category.id.toString()),
      }))
      : undefined,
    visible: true,
    fade: true,
    back: true,
    title: isMobileApp && !hasScrolled
      ? <LogoLink variant="white" className="max-w-[92px] w-full" />
      : undefined,
    className: {
      child: '!items-start',
    },
  }

  const featuredImage: PublicLayoutProps['featuredImage'] = {
    image: magazineHero,
    title: 'A travel & lifestyle magazine dedicated to capturing the beauty of our destinations',
    darken: true,
    mobile: {
      showTitle: true,
      position: 'content',
    },
    search: {
      element: <MagazineSearchBar />,
    },
    size: 'lg',
  }

  return (
    <FormProvider {...form}>
      <PublicLayout
        seo={seo}
        mobileNavBar={mobileNavbar}
        featuredImage={featuredImage}
      >
        {
          (!isMobile && hasScrolled)
            ? <MagazineSearchbar />
            : null
        }

        {isMobile ? <hr className="mt-50 border-grey-230 mb-25" /> : undefined}

        <MagazineHero />

        <hr className="border-grey-230" />

        <TravelGuides articles={initialData?.travelGuides} />

        <FeaturedArticle article={initialData?.featuredArticle} />

        <SectionHeaders.Magazine
          className="mb-40 font-serif text-center text-grey-900 lg:mb-75 lg:mt-100 mt-50 !font-bold md:!font-medium"
        >
          Most Recent Articles
        </SectionHeaders.Magazine>

        {
          initialData?.recentArticles?.pages[0]
          && <>
            {
              initialData.recentArticles.pages[0].items.length === 0
                ? <div className="text-center">
                  No Relevant Article
                </div>
                : (isMobile
                  ? (
                    <MobileArticleList
                      className={{
                        container: 'px-24',
                      }}
                      scrollRef={false}
                      articles={initialData.recentArticles as InfiniteData<RestArticlePage>}
                      button={
                        <Link href="/magazine/category">
                          <Button className={{
                            button: 'mx-auto uppercase !w-[160px] !py-12 !px-24',
                          }}>
                            View More
                          </Button>
                        </Link>
                      }
                    />
                  )
                  : (
                    <ArticleList articles={initialData.recentArticles}
                      button={
                        <Link href="/magazine/category">
                          <Button className={{
                            button: 'mx-auto uppercase max-w-[200px] w-full rounded-4',
                          }}>
                            View More
                          </Button>
                        </Link>
                      }
                    />
                  )
                )}
          </>
        }

        <hr className="mb-20 mt-50 border-grey-230 md:mb-0 md:mt-100" />

        <TopPicks articles={initialData?.topPicks} />

        {!isMobileApp
          ? (
            <>
              <hr className="border-grey-230 mt-50 mb-75 lg:mt-100" />

              <MostViewed articles={initialData?.mostViewed} />

              <hr className="border-none mb-75" />

              <CreateAnAccount />
            </>
          )
          : undefined
        }

      </PublicLayout>
    </FormProvider>
  )
}

export async function getServerSideProps ({ req }: GetServerSidePropsContext) {
  const [categories, recentArticles, topPicks, featuredArticle, travelGuides, mostViewed] = await Promise.allSettled([
    ApiClient.withReq(req).magazine.getCategories(),
    ApiClient.magazine.getArticles({ per_page: 4 }),
    ApiClient.magazine.getArticles({ per_page: 6, acf_tag: 'Top Picks: Top Picks' }),
    ApiClient.magazine.getArticles({ per_page: 1, page: 1, featured_article: true }),
    ApiClient.magazine.getArticles({ per_page: 6, acf_tag: 'Travel Guides: Travel Guides' }),
    ApiClient.magazine.getArticles({ per_page: 9, most_viewed: true }),
  ])
  return {
    props: {
      initialData: {
        recentArticles: { pages: [(recentArticles as PromiseSettledResult).value] },
        categories: (categories as PromiseSettledResult).value ?? [],
        topPicks: (topPicks as PromiseSettledResult).value ?? [],
        featuredArticle: (featuredArticle as PromiseSettledResult).value?.items[0] ?? {},
        travelGuides: (travelGuides as PromiseSettledResult).value ?? [],
        mostViewed: (mostViewed as PromiseSettledResult).value ?? [],
      },
    },
  }
}

export default MagazinePage
