import React from 'react'
import MagazineCarouselSection from 'components/sections/magazine/MagazineCarouselSection'
import { RestArticlePage } from 'data/types/article'
import { useScreenWidth } from 'data/hooks/useScreenWidth'

const TravelGuides = ({ articles }: { articles: RestArticlePage }) => {
  const { isMobile } = useScreenWidth(1024)

  return articles && (
    <MagazineCarouselSection
      showRegion
      className={{
        container: 'mt-25 md:mt-[77px] md:mb-[179px] whitespace-break-spaces',
      }}
      articles={articles}
      subheading="TRAVEL GUIDES"
      heading={isMobile ? 'Explore our local\ninsider travel guides' : 'Explore our local\ninsider travel\nguides'}
      description="Whether you're visiting for a day or staying for a week, our travel guides offer inspiring itineraries. Designed with our clients in mind, these travel guides offer curated recommendations, things to do and places to visit."
      button={{
        href: '/magazine/category?search=Travel Guides',
        text: 'View All',
      }}
    />
  )
}

export default TravelGuides
